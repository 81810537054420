.ModalRules {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .4);
}

.ModalContentRules {
    margin: 10px auto 0;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    // background-color: green;
    border-radius: 5px;
    color: #33334d; 
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 20px 20px 0;

    h5 {
        font-size: 52px;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
        font-size: 26px;
        line-height: 1.5;
        padding: 12px 30px;
    }

    button {
        font-size: 32px;
        border-radius: 5px;
        border: none;
        padding: 12px 22px;
        background-color: #33334d;
        color: whitesmoke;
        /* color: #33334d; */
        /* background-color: rgba(255, 255, 255, 1); */
        -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        letter-spacing: 1.2px;
        margin-top: 10px;
        margin-bottom: 20px;

        &:hover {
            cursor: pointer;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    .ModalContentRules {
    
        h5 {
            font-size: 42px;
            margin: 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    
        p {
            margin: 0;
            font-size: 24px;
            line-height: 1.5;
            padding: 12px 28px;
        }
    
        button {
            font-size: 28px;
            padding: 12px 22px;
            margin-top: 10px;
            margin-bottom: 20px;
    
        }
    }
}

@media (max-width: 741px) and (orientation: landscape){

    .ModalContentRules {
    
        h5 {
            font-size: 28px;
            margin: 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    
        p {
            margin: 0;
            font-size: 18px;
            padding: 12px 26px;
            line-height: 1.2;
        }
    
        button {
            font-size: 18px;
            padding: 8px 12px;
            margin-top: 10px;
            margin-bottom: 10px;
    
        }
    }

}

@media (min-width: 481px) and (max-width: 767px) {
  
    .ModalContentRules {
    
        h5 {
            font-size: 30px;
            margin: 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    
        p {
            margin: 0;
            font-size: 18px;
            line-height: 1.5;
            padding: 10px 28px;
        }
    
        button {
            font-size: 20px;
            padding: 10px 20px;
            margin-top: 10px;
            margin-bottom: 20px;
    
        }
    }
}

@media (min-width: 320px) and (max-width: 480px) {
  
    .ModalContentRules {
    
        h5 {
            font-size: 28px;
            margin: 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    
        p {
            margin: 0;
            font-size: 16px;
            padding: 4px 22px 8px;
        }
    
        button {
            font-size: 20px;
            padding: 8px 12px;
            margin-top: 10px;
            margin-bottom: 10px;
    
        }
    }
}
