.Modal {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    left: 0;
}

.ModalContent {
    margin: 30px auto 0;
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;

    h5 {
        font-size: 52px;
        margin: 0;
        margin-top: 20px;
        margin-bottom: 10px;
        color: #33334d;
    }

    p {
        font-size: 22px;
        margin: 0;
        padding: 0;
        margin-bottom: 12px;
        /* margin-top: 20px;
        color: #33334d; */

        span{
            font-size: 22px;
            /* margin: 0;
            margin-bottom: 12px; */
            color: rgb(19, 167, 0)
        }
    }

    .PositionCheck {

        .Span1 {
            animation: text1 5s infinite;
        }

        .Span2 {
            animation: text2 5s infinite;
        }
    }
}

.DivLowScore {
    width: 100%;
    // background-color: green;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
        padding: 0;
    }

    .BtnPlayAgain {
        background-color: #33334d;
        border: none;
        color: whitesmoke;
        padding: 10px 20px;
        border-radius: 5px;
    
        &:hover {
            cursor: pointer;
        }
    }
}

.DivClose {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 0 0;
    height: 42px;
    /* background-color: royalblue; */

    span {
        font-size: 42px;

        &:hover {
            color: rgb(190, 0, 0);
            cursor: pointer;
        }
    }
}

.FormModal {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    /* align-items: center; */

    p{
        /* color: #33334d; */
        font-size: 20px;
    }
}


.FormDivModal {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    label {
        font-size: 20px;
        margin-right: 10px;
        /* margin-bottom: 10px; */
    }

    input {
        width: 100%;
        font-size: 20px;
        padding: 5px 10px;
        border: 1px solid #33334d;
        border-radius: 5px;
    }
}


.FormDivBtnModal {
    display: flex;
    align-self: center;
    justify-content: center;
    margin-top: 20px;

    input {
        font-size: 22px;
        border-radius: 5px;
        border: none;
        padding: 12px 22px;
        background-color: #33334d;
        color: whitesmoke;
        -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        letter-spacing: 1.2px;
        margin-bottom: 20px;

        &:hover {
            cursor: pointer;
        }
    }
}

@keyframes text1 {
    0% {
        color: hsl(7, 80%, 40%);
    }
    10% {
        color: hsl(69, 80%, 40%);
    }
    20% {
        color: hsl(79, 80%, 40%);
    }
    30% {
        color: hsl(111, 80%, 40%);
    }
    40% {
        color: hsl(143, 80%, 40%);
    }
    50% {
        color: hsl(176, 80%, 40%);
    }
    60% {
        color: hsl(208, 80%, 40%);
    }
    70% {
        color: hsl(241, 80%, 40%);
    }
    80% {
        color: hsl(273, 80%, 40%);
    }
    90% {
        color: hsl(307, 80%, 40%);
    }
    100% {
        color: hsl(340, 80%, 40%);
    }
}

@keyframes text1 {
    0% {
        color: hsl(340, 80%, 40%);    
    }
    10% {
        color: hsl(307, 80%, 40%);    
    }
    20% {
        color: hsl(273, 80%, 40%);    
    }
    30% {
        color: hsl(241, 80%, 40%);   
    }
    40% {
        color: hsl(208, 80%, 40%);
    }
    50% {
        color: hsl(176, 80%, 40%);
    }
    60% {
        color: hsl(143, 80%, 40%);
    }
    70% {
        color: hsl(111, 80%, 40%);
    }
    80% {
        color: hsl(79, 80%, 40%);
    }
    90% {
        color: hsl(69, 80%, 40%);
    }
    100% {
        color: hsl(7, 80%, 40%);
    }
}

@media (min-width: 220px) and (max-width: 480px) {
  
    .ModalContent {
        margin: 20% auto 0;
        width: 90%;
        padding: 0 10px 20px;

        h5 {
            font-size: 38px;
            margin: 0;
            margin-top: 16px;
            margin-bottom: 20px;
        }

        p {
            font-size: 20px;
            margin: 0;
            padding: 0;
            margin-bottom: 12px;
            /* margin-top: 20px;
            color: #33334d; */

            span{
                font-size: 20px;
            }
        }

        .PositionCheck {

            .Span1 {
                animation: text1 5s infinite;
            }

            .Span2 {
                animation: text2 5s infinite;
            }
        }
    }

   
.DivLowScore {
    width: 100%;
    // background-color: green;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
        padding: 0;
    }

    .BtnPlayAgain {
        background-color: #33334d;
        border: none;
        color: whitesmoke;
        padding: 10px 20px;
        border-radius: 5px;
    
        &:hover {
            cursor: pointer;
        }
    }
}


    .FormModal {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        /* align-items: center; */

        p{
            /* color: #33334d; */
            padding: 0 20px;
            font-size: 18px;
        }
    }


    .FormDivModal {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 8px;
        margin-bottom: 8px;
        height: 100px;
        justify-content: space-around;
        // background-color: green;
        padding: 10px 20px;

        label {
            font-size: 18px;
            margin-right: 10px;
            /* margin-bottom: 10px; */
        }

        input {
            width: 100%;
            font-size: 18px;
            padding: 5px 10px;
            border: 1px solid #33334d;
            border-radius: 5px;
        }
    }


    .FormDivBtnModal {
        display: flex;
        align-self: center;
        justify-content: center;
        margin-top: 20px;

        input {
            font-size: 20px;
            border-radius: 5px;
            border: none;
            padding: 8px 16px;
        }
    }

}
