.DivWelcome {
    width: 100%;
    height: 100vh;
    background-color: #33334d;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.DivTitle {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;

}

.DivTitle {
    h1 {
        font-size: 72px;
        text-shadow: 3px 0px 4px rgba(255, 255, 255, 0.4);
        /* font-size: 3rem; */
    }
    h2 {
        font-size: 48px;
        margin: 0;
        text-shadow: 3px 0px 4px rgba(255, 255, 255, 0.4);
    }
}

.DivBtns {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: yellowgreen; */
    margin-top: 60px;

    button {
        font-size: 32px;
        border-radius: 5px;
        border: none;
        padding: 12px 22px;
        color: #33334d;
        background-color: rgba(255, 255, 255, 1);
        -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        letter-spacing: 1.2px;

        &:hover {
            color: rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, .4);
            cursor: pointer;
        }
    }
}

.Link {
    font-size: 32px;
    border-radius: 5px;
    border: none;
    padding: 12px 22px;
    color: #33334d;
    background-color: rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
    -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
    box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
    letter-spacing: 1.2px;
    text-decoration: none;

    &:hover {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(0, 0, 0, .4);
        cursor: pointer;
    }
}

footer {
    width: 100%;
    height: 60px;
    /* background-color: yellow; */
    -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
    -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
    box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
    /* background-color: rgba(0, 0, 0, .4); */
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: none;
        font-size: 32px;
        color: whitesmoke;
    }
}

// responsive

@media (max-width: 768px){
        
    .DivTitle {
        h1 {
            font-size: 52px;
            margin-top: 60px;
        }
        h2 {
            font-size: 32px;
        }
    }

    .DivBtns {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        /* background-color: yellowgreen; */
        margin-top: 90px;

        button {
            font-size: 22px;
            padding: 12px 22px;
            letter-spacing: 1.2px;
        }
    }

    .Link {
        font-size: 22px;
        padding: 12px 22px;
        letter-spacing: 1.2px;

    }

    footer {
        height: 60px;
        a {
            font-size: 28px;
        }
    }
}

@media (max-width: 741px) and (orientation: landscape){
        
    .DivTitle {
        h1 {
            font-size: 52px;
            margin-top: 20px;
        }
        h2 {
            font-size: 32px;
        }
    }

    .DivBtns {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        /* background-color: yellowgreen; */
        margin-top: 30px;

        button {
            font-size: 20px;
            padding: 10px 20px;
            letter-spacing: 1.2px;
        }
    }

    .Link {
        font-size: 20px;
        padding: 10px 20px;
        letter-spacing: 1.2px;

    }

    footer {
        height: 50px;
        a {
            font-size: 28px;
        }
    }
}

@media (max-width: 600px){
        
    .DivTitle {
        h1 {
            font-size: 38px;
            margin-top: 80px;
        }
        h2 {
            font-size: 26px;
        }
    }

    .DivBtns {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        /* background-color: yellowgreen; */
        margin-top: 90px;

        button {
            font-size: 20px;
            padding: 8px 16px;
            letter-spacing: 1.2px;
        }
    }

    .Link {
        font-size: 20px;
        padding: 8px 16px;
        letter-spacing: 1.2px;

    }

    footer {
        height: 50px;
        a {
            font-size: 22px;
        }
    }
}

@media (max-width: 420px){
        
    .DivTitle {
        h1 {
            font-size: 28px;
            margin-top: 80px;
        }
        h2 {
            font-size: 20px;
            margin-top: 40px;
        }
    }

    .DivBtns {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        /* background-color: yellowgreen; */
        margin-top: 90px;

        button {
            font-size: 18px;
            padding: 8px 12px;
            letter-spacing: 1.2px;
        }
    }

    .Link {
        font-size: 18px;
        padding: 8px 12px;
        letter-spacing: 1.2px;

    }

    footer {
        height: 50px;
        a {
            font-size: 22px;
        }
    }
}

@media (max-width: 320px){
        
    .DivTitle {
        h1 {
            font-size: 28px;
            margin-top: 80px;
        }
        h2 {
            font-size: 20px;
            margin-top: 40px;
        }
    }

    .DivBtns {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        /* background-color: yellowgreen; */
        margin-top: 50px;

        button {
            font-size: 18px;
            padding: 8px 12px;
            letter-spacing: 1.2px;
        }
    }

    .Link {
        font-size: 18px;
        padding: 8px 12px;
        letter-spacing: 1.2px;

    }

    footer {
        height: 50px;
        a {
            font-size: 22px;
        }
    }
}
