$fontsAndRing: #f5f5f581;
$background: #33334d;
// $fontsAndRing: whitesmoke;


.DivLoader {
    width: 100vw;
    height: 100vh;
    background-color: $background;
}

.LoaderRing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    height: 180px;
    background: transparent;
    border: 4px solid $fontsAndRing;
    border-radius: 50%;
    text-align: center;
    line-height: 170px;
    font-size: 2.8vw;
    // color: #d1d959;
    color: whitesmoke;
    /* text-shadow: 0 0 10px #d1d959; */
    letter-spacing: 2px;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    animation: textGlow 3s infinite;

    &::before{
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-top: 4px solid whitesmoke;
    border-right: 4px solid whitesmoke;
    border-radius: 50%;
    animation: animate 2s linear infinite;
    }
}

// .LoaderRing::before {
//     content: '';
//     position: absolute;
//     top: -4px;
//     left: -4px;
//     width: 100%;
//     height: 100%;
//     border: 4px solid transparent;
//     border-top: 4px solid #d1d959;
//     border-right: 4px solid #d1d959;
//     border-radius: 50%;
//     animation: animate 2s linear infinite;

// }

.Span {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: animateCircle 2s linear infinite;

    &::before{
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: whitesmoke;
        top: -6px;
        right: -8px;
        box-shadow: 0 0 20px whitesmoke;
    }
}

// .Span::before {
//     content: '';
//     position: absolute;
//     width: 16px;
//     height: 16px;
//     border-radius: 50%;
//     background: #d1d959;
//     top: -6px;
//     right: -8px;
//     box-shadow: 0 0 20px #d1d959;
// }

@keyframes animate {

    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animateCircle {

    0% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(405deg);
    }
}
@keyframes textGlow {

    0% {
        text-shadow: 0 0 5px whitesmoke;
    }
    100% {
        text-shadow: 0 0 20px whitesmoke;
    }
}