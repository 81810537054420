.GameContainer {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr;
    // grid-template-columns: 350px 1fr;
    grid-template-columns: 30% 1fr;
}

.Sidebar {
    grid-row: 1/1;
    grid-column: 1/2;
}

.Main {
    grid-row: 1/1;
    grid-column: 2/3;
}


@media (min-width: 481px) and (max-width: 767px) {
  
    .GameContainer {
        grid-template-columns: 220px 1fr;
    }
}

@media (min-width: 220px) and (max-width: 480px) {
  
    .GameContainer {
        width: 100%;
        height: 100vh;
        display: grid;
        grid-template-rows: 140px 1fr;
        grid-template-columns: 100%;
    }
    
    .Sidebar {
        grid-row: 1/1;
        grid-column: 1/2;
    }
    
    .Main {
        grid-row: 2/2;
        grid-column: 1/2;
    }
}