.DivMain {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #33334d;
    justify-content: flex-end;
    align-items: center;
}

.ImgGame {
    // background: url('../img/landscape.jpg') top;
    // background-size: 100% 100vh;
    // background-position: 50% 50%;
    // background-repeat: no-repeat;
    // width: calc(100% - 350px);
    width: 70%;
    height: 100vh;
    background-color: #33334d;
    position: absolute;
    z-index: 1;
}

.StartGame {
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.StartGameNoShow {
    display: none;
}

.StartBtn {
    background-color: #33334d;
    border: none;
    font-size: 1.5rem;
    color: whitesmoke;
    padding: 10px 20px;
    border-radius:15px;

    &:hover {
        cursor: pointer;
    }
}

.OperationNoShow {
    display: none;
}

.Operation {
    z-index: 5;
    background-color: cornsilk;
    border-radius: 25px;
    border: 2px solid black;
    min-width: 100px;
    padding: 10px 20px;
    text-align: center;
    font-size: 30px;
    position: absolute;
    top: 0;
    margin: auto;
    margin-top: 5px;
    display: block;
}

.SpanSign {
    /* color: rgb(19, 167, 0); */
    margin: 0 10px;
    font-size: 32px;
}



.OperationAnimation {
    animation: opDown;
    /* animation-duration: 10s; */
    /* animation-delay: 1s; */
    animation-play-state: initial;
}

.OperationAnimationPause {
    animation-play-state: paused;
}

.ResultNoShow {
    display: none;
}

.Result {
    display: block;
    z-index: 5;
    /* background-color: cornsilk; */
    /* position: absolute; */
    /* bottom: 10px; */
    margin-bottom: 6px;
}



%Res {
    font-size: 35px;
    margin: 0 20px;
    /* margin-bottom: 30px; */
    background-color: lightskyblue;
    border-radius: 25px;
    border: 2px solid white;
    padding: 0px 20px;

    &:hover {
        cursor: pointer;
    }
}

.Res1, .Res2, .Res3 {
    @extend %Res;
}

@keyframes opDown {
    0% {
        top: 0vh;
    }
    5% {
        top: 8vh;
    }
    10% {
        top: 12vh;
    }
    15% {
        top: 16vh;
    }
    20% {
        top: 20vh;
    }
    25% {
        top: 24vh;
    }
    30% {
        top: 28vh;
    }
    35% {
        top: 32vh;
    }
    40% {
        top: 36vh;
    }
    45% {
        top: 40vh;
    }
    50% {
        top: 44vh;
    }
    55% {
        top: 48vh;
    }
    60% {
        top: 52vh;
    }
    65% {
        top: 56vh;
    }
    70% {
        top: 60vh;
    }
    75% {
        top: 64vh;
    }
    80% {
        top: 68vh;
    }
    85% {
        top: 71vh;
    }
    90% {
        top: 73vh;
    }
    95% {
        top: 75vh;
    }
    100% {
        top: 82vh;
    }
}


@keyframes opDownCellPortrait {
    0% {
        top: calc(140px + 0%);
    }
    5% {
        top: calc(140px + 3%);
    }
    10% {
        top: calc(140px + 5%);
    }
    15% {
        top: calc(140px + 8%);
    }
    20% {
        top: calc(140px + 10%);
    }
    25% {
        top: calc(140px + 12%);
    }
    30% {
        top: calc(140px + 14%);
    }
    35% {
        top: calc(140px + 16%);
    }
    40% {
        top: calc(140px + 18%);
    }
    45% {
        top: calc(140px + 20%);
    }
    50% {
        top: calc(140px + 23%);
    }
    55% {
        top: calc(140px + 25%);
    }
    60% {
        top: calc(140px + 28%)
    }
    65% {
        top: calc(140px + 30%);
    }
    70% {
        top: calc(140px + 33%);
    }
    75% {
        top: calc(140px + 36%);
    }
    80% {
        top: calc(140px + 40%);
    }
    85% {
        top: calc(140px + 43%);
    }
    90% {
        top: calc(140px + 48%);
    }
    95% {
        top: calc(140px + 52%);
    }
    100% {
        top: calc(100% - 90px);
    }
}


@media (min-width: 481px) and (max-width: 767px) {
           
    .ImgGame {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        // width: calc(100% - 220px);
        width: calc(100% - 220px);
        height: 100vh;
    }

    .StartBtn {
        font-size: 1.2rem;
        padding: 10px 20px;
    }

    .Operation {
        min-width: 80px;
        padding: 8px 12px;
        font-size: 26px;
    }

    .SpanSign {
        /* color: rgb(19, 167, 0); */
        margin: 0 10px;
        font-size: 32px;
    }

    %Res {
        font-size: 35px;
        margin: 0 20px;
        /* margin-bottom: 30px; */
        background-color: lightskyblue;
        border-radius: 25px;
        border: 2px solid white;
        padding: 0px 20px;
    }
}

@media (min-width: 220px) and (max-width: 480px) {

    .DivMain {
        width: 100%;
        // height: 100%;
        height: calc(100vh - 140px);
    }

    .ImgGame {
        background-position: 50% 100%;
        // background-position: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: calc(100vh - 140px);
        // height: 100%;
    }

    .StartBtn {
        font-size: 1rem;
        padding: 10px 20px;
    }

    .Operation {
        z-index: 5;
        background-color: cornsilk;
        border-radius: 25px;
        border: 2px solid black;
        min-width: 50px;
        padding: 4px 10px;
        text-align: center;
        font-size: 20px;
        position: absolute;
        top: 140px;
        margin: auto;
        // margin-top: 5px;
        display: block;
    }

    .OperationAnimation {
        animation: opDownCellPortrait;
        /* animation-duration: 10s; */
        /* animation-delay: 1s; */
        animation-play-state: initial;
    }
    
    .OperationAnimationPause {
        animation-play-state: paused;
    }


    .SpanSign {
        /* color: rgb(19, 167, 0); */
        margin: 0 10px;
        font-size: 26px;
    }

    %Res {
        font-size: 22px;
        margin: 0 12px 16px;
        /* margin-bottom: 30px; */
        background-color: lightskyblue;
        border-radius: 25px;
        border: 2px solid white;
        padding: 0px 16px;
    }

}

