$bgc:#33334d;
$color: whitesmoke;


.DivTop {
    width: 100%;
    min-height: 100vh;
    background-color: $bgc;
    color: $color;
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
        width: 100%;
        padding: 8px 20px;
        -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        display: grid;
        grid-template-columns: 25% 50% 25%;

        .Div1 {
            grid-column: 1/2;
        }

        .Div2 {
            grid-column: 2/3;
            justify-self: center;

            h2 {
                margin: 0;
                font-size: 48px;
                text-shadow: 3px 0px 4px rgba(255, 255, 255, 0.4);
            }
        }

        .Div3 {
            grid-column: 3/4;
            justify-self: end;
            align-self: center;

            a {
                font-size: 20px;
                border-radius: 5px;
                border: none;
                padding: 8px 12px;
                margin-right: 20px;
                color: #33334d;
                background-color: rgba(255, 255, 255, 1);
                -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
                -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
                box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
                letter-spacing: 1.2px;
                text-decoration: none;
            
                &:hover {
                    color: rgba(255, 255, 255, 1);
                    background-color: rgba(0, 0, 0, .4);
                    cursor: pointer;
                }
            }
        }
    }

    h1 {
        margin: 0;
        margin-top: 10px;
        font-size: 58px;
        letter-spacing: 2px;
        text-shadow: 3px 0px 4px rgba(255, 255, 255, 0.4);
    }
}

.DivTableContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .DivTable {
        border: 1px solid whitesmoke;
        border-radius: 5px;
        -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 30px;
        margin-bottom: 30px;

        table {
        
            width: 100%;
            // margin: 0 20px 30px;
    
            thead {       
                tr {            
                    th {
                        font-size: 24px;
                        padding: 8px 14px;
                    }
                }
            }
    
            tbody{
                tr {
                    td{
                        text-align: center;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}


@media (min-width: 481px) and (max-width: 767px) {
  
    
    .DivTop {
        header {
            width: 100%;
            padding: 8px 10px;
            // display: grid;
            // grid-template-columns: 25% 50% 25%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .Div1 {
                // grid-column: 1/2;
                display: none;
            }

            .Div2 {
                width: 50%;

                h2 {
                    font-size: 38px;
                    margin-left: 20px;
                }
            }

            .Div3 {
                width: 50%;
                display: flex;
                justify-content: flex-end;

                a {
                    font-size: 20px;
                    border-radius: 5px;
                    border: none;
                    padding: 8px 12px;
                    margin-right: 20px;
                    &:first-child{
                        margin-right: 40px;
                    }
                }
            }
        }

        h1 {
            margin: 0;
            margin-top: 10px;
            font-size: 48px;
            letter-spacing: 2px;
            text-shadow: 3px 0px 4px rgba(255, 255, 255, 0.4);
        }
    }

    .DivTableContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        .DivTable {
            border: 1px solid whitesmoke;
            border-radius: 5px;
            -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
            -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
            box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 30px;
            margin-bottom: 30px;

            table {
            
                width: 100%;
                // margin: 0 20px 30px;
        
                thead {       
                    tr {            
                        th {
                            font-size: 22px;
                            padding: 8px 12px;
                        }
                    }
                }
        
                tbody{
                    tr {
                        td{
                            text-align: center;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 401px) and (max-width: 480px) {
  
    
    .DivTop {
        header {
            width: 100%;
            padding: 8px 10px;
            // display: grid;
            // grid-template-columns: 25% 50% 25%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .Div1 {
                // grid-column: 1/2;
                display: none;
            }

            .Div2 {
                width: 50%;

                h2 {
                    font-size: 32px;
                    margin-left: 20px;
                }
            }

            .Div3 {
                width: 50%;
                display: flex;
                justify-content: flex-end;

                a {
                    font-size: 18px;
                    border-radius: 5px;
                    border: none;
                    padding: 4px 8px;
                    margin-right: 10px;
                    &:first-child{
                        margin-right: 30px;
                    }
                }
            }
        }

        h1 {
            margin: 0;
            margin-top: 10px;
            font-size: 38px;
            letter-spacing: 2px;
            text-shadow: 3px 0px 4px rgba(255, 255, 255, 0.4);
        }
    }

    .DivTableContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        padding: 0 20px;

        .DivTable {
            border: 1px solid whitesmoke;
            border-radius: 5px;
            -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
            -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
            box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 30px;
            margin-bottom: 30px;

            table {
            
                width: 100%;
                // margin: 0 20px 30px;
        
                thead {       
                    tr {            
                        th {
                            font-size: 20px;
                            padding: 8px 12px;
                        }
                    }
                }
        
                tbody{
                    tr {
                        td{
                            text-align: center;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 400px) {
  
    .DivTop {
        header {
            width: 100%;
            padding: 8px 0;
            // display: grid;
            // grid-template-columns: 25% 50% 25%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .Div1 {
                // grid-column: 1/2;
                display: none;
            }

            .Div2 {
                width: 50%;

                h2 {
                    font-size: 28px;
                    margin-left: 16px;
                }
            }

            .Div3 {
                width: 50%;
                display: flex;
                justify-content: flex-end;

                a {
                    font-size: 16px;
                    border-radius: 5px;
                    border: none;
                    padding: 4px 8px;
                    margin-right: 10px;
                    &:first-child{
                        margin-right: 20px;
                    }
                }
            }
        }

        h1 {
            margin: 0;
            margin-top: 10px;
            font-size: 28px;
            letter-spacing: 2px;
            text-shadow: 3px 0px 4px rgba(255, 255, 255, 0.4);
        }
    }

    .DivTableContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        padding: 0 20px;

        .DivTable {
            border: 1px solid whitesmoke;
            border-radius: 5px;
            -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
            -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
            box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            margin-bottom: 30px;

            table {
            
                width: 100%;
                // margin: 0 20px 30px;
        
                thead {       
                    tr {            
                        th {
                            font-size: 18px;
                            padding: 6px 10px;
                        }
                    }
                }
        
                tbody{
                    tr {
                        td{
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
