.DivSidebar {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #33334d;
    color: whitesmoke;
    align-items: center;
    // justify-content: space-evenly;
    .DivScore {
        margin-top: 20px;
        h3 {
            font-size: 26px;
            padding: 0;
            margin: 0;
        }
    }
    
}

.DivScoreAndOptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.DivOption {
    display: flex;
    /* background-color: green; */
    margin-top: 40px;
}

.Form {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    font-size: 18px;

    p{
        font-size: 22px;
    }

    input[type="radio"]{
        margin-right: 10px;
    }
}

.FormDivContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FormDiv {
    display: flex;
    /* align-items: center; */
    /* background-color: red; */
    margin-bottom: 10px;
    width: 140px;
    /* margin-left:20px; */
}


.FormDivInput {
    display: flex;
    align-items: center;
    justify-content: center;

    input{
        margin-top: 20px;
        font-size: 20px;
        border-radius: 5px;
        border: none;
        padding: 12px 22px;
        color: #33334d;
        background-color: rgba(255, 255, 255, 1);
        -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        letter-spacing: 1.2px;

        &:hover {
            color: rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, .4);
            cursor: pointer;
        }
    }
}

.DivSidebarBtn {
    width: 100%;
    display: flex;
    // background-color: green;
    margin-top: 20px;
    justify-content: space-around;

    a {
        font-size: 20px;
        border-radius: 5px;
        border: none;
        padding: 8px 12px;
        color: #33334d;
        background-color: rgba(255, 255, 255, 1);
        -webkit-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        -moz-box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        box-shadow: 0px -1px 13px 0px rgba(255,255,255,.4);
        letter-spacing: 1.2px;
        text-decoration: none;
    
        &:hover {
            color: rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, .4);
            cursor: pointer;
        }
    }
}

@media (max-width: 825px) {

    .DivSidebar {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: #33334d;
        color: whitesmoke;
        align-items: center;
        // justify-content: space-evenly;
        .DivScore {
            margin-top: 20px;

            h1 {
                font-size: 26px;
            }
            h3 {
                font-size: 24px;
            }
        }
        
    }

    .DivOption {
        display: flex;
        /* background-color: green; */
        margin-top: 40px;
    }
    
    .Form {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        font-size: 16px;
    
        p{
            font-size: 18px;
            padding: 0 5%;
        }
    
        input[type="radio"]{
            margin-right: 10px;
        }
    }
    
    .FormDiv {
        display: flex;
        /* align-items: center; */
        /* background-color: red; */
        margin-bottom: 10px;
        // width: 140px;
        width: 100%;
        margin-left: 15%;
        /* margin-left:20px; */
    }
    
    .DivSidebarBtn {
        width: 100%;
        display: flex;
        // background-color: green;
        margin-top: 20px;
        justify-content: space-around;
    
        a {
            font-size: 16px;
            padding: 4px 8px;
            letter-spacing: 1px;
        }
    }
}


@media (min-width: 481px) and (max-width: 767px) {
  
    .DivSidebar {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: #33334d;
        color: whitesmoke;
        align-items: center;
        justify-content: flex-start;
        .DivScore {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                margin: 10px 0;
                font-size: 26px;
            }
            h3 {
                font-size: 22px;
            }
        }
        
    }

    .DivOption {
        display: flex;
        /* background-color: green; */
        margin-top: 10px;
    }
    
    .Form {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        font-size: 16px;
    
        p{
            font-size: 18px;
            padding: 0 5%;
        }
    
        input[type="radio"]{
            margin-right: 10px;
        }
    }
    
    .FormDiv {
        display: flex;
        /* align-items: center; */
        /* background-color: red; */
        margin-bottom: 10px;
        // width: 140px;
        width: 100%;
        margin-left: 15%;
        /* margin-left:20px; */
    }
    
    .DivSidebarBtn {
        width: 100%;
        display: flex;
        // background-color: green;
        margin-top: 16px;
        justify-content: space-around;
    
        a {
            font-size: 16px;
            padding: 4px 8px;
            letter-spacing: 1px;
        }
    }
    
}

@media (min-width: 220px) and (max-width: 480px) {

    .DivSidebar {
        width: 100%;
        height: 150px;
        display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        .DivScore {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 50%;
            align-items: center;
            // justify-content: center;
            // background-color: yellowgreen;

            h1 {
                font-size: 20px;
            }
            h3 {
                font-size: 16px;
            }
        }
        
    }

    .DivScoreAndOptionContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 2px 0 0;
    }

    .DivOption {
        display: flex;
        /* background-color: green; */
        margin-top: 10px;
        width: 50%;
        height: 100%;
        // background-color: green;
        justify-content: center;
        align-items: center;
    }
    
    .Form {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        font-size: 10px;
    
        p{
            font-size: 14px;
            padding: 0;
            margin: 0;
            margin-bottom: 4px;
        }
    
        input[type="radio"]{
            margin-right: 6px;
        }
    }
    
    .FormDiv {
        display: flex;
        align-items: flex-end;
        // background-color: red;
        margin-bottom: 4px;
        // width: 140px;
        width: 100%;
        margin-left: 15%;
        /* margin-left:20px; */
    }
    
    .DivSidebarBtn {
        width: 100%;
        display: flex;
        // background-color: green;
        margin-top: 8px;
        justify-content: space-around;
    
        a {
            font-size: 12px;
            padding: 4px 8px;
            letter-spacing: 1px;
        }
    }
}
